import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from 'axios';

const API_PATH_PREFIX = 'api';
const axiosConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
};

const instance: AxiosInstance = axios.create(axiosConfig);
const axiosBaseQuery =
  (
    { baseUrl, axiosInstance }: { baseUrl: string; axiosInstance: AxiosInstance } = { baseUrl: '', axiosInstance: instance }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axiosInstance({ url: `${baseUrl}${url}`, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      console.error(err);
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const Api = createApi({
  reducerPath: 'Api',
  baseQuery: axiosBaseQuery({ baseUrl: API_PATH_PREFIX, axiosInstance: instance }),
  endpoints: () => ({}),
});

export { Api, API_PATH_PREFIX, instance };
