import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import CustomButton from '../../../components/customButton';

export default function WelcomeModal() {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<'android' | 'ios' | null>(null);

  const androidUrl = 'https://play.google.com/store';
  const iosUrl = 'https://apps.apple.com';

  const handlePlatformSelection = (platform: 'android' | 'ios') => {
    setSelectedPlatform(platform);
  };

  const qrUrl = selectedPlatform === 'android' ? `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(androidUrl)}` : selectedPlatform === 'ios' ? `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(iosUrl)}` : null;

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');

    if (!hasSeenModal) {
      setShowModal(true);
      localStorage.setItem('hasSeenModal', 'true');
    }
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>¡Bienvenido a PetClub!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        
          <p className="text-center mt-4">Descarga nuestra app:</p>
          <Row className="justify-content-center">
            <Col xs="auto">
              <CustomButton styleType="style-1" onClick={() => handlePlatformSelection('android')} animation="fadeIn" duration="0.3s">
                <FontAwesomeIcon icon={faAndroid} /> Android
              </CustomButton>
            </Col>
            <Col xs="auto">
              <CustomButton styleType="style-1" onClick={() => handlePlatformSelection('ios')} animation="fadeIn" duration="0.3s">
                <FontAwesomeIcon icon={faApple} /> iOS
              </CustomButton>
            </Col>
          </Row>

          {selectedPlatform && (
            <div className="text-center mt-4">
              <p>Escanea el QR para descargar la aplicación en {selectedPlatform === 'android' ? 'Android' : 'iOS'}:</p>
              <img src={qrUrl!} alt={`QR Code ${selectedPlatform}`} className="mt-2" />
            </div>
          )}
        </div>{' '}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
