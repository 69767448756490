import React from 'react';
import { Plan } from '../../PlanApiSlice/Representations/Plan';
import PlanCard from '../PlanCard';

interface PlansListProps {
  plans: Plan[];
  selectedPlanId: number | null;
  onSelectPlan: (plan: Plan) => void;
}

export default function PlanList({ plans, selectedPlanId, onSelectPlan }: PlansListProps) {
  const activePlans = plans.filter((plan) => plan.isactive);

  return (
    <div className="d-flex justify-content-center flex-wrap mb-100 mt-50">
      {activePlans.map((plan) => (
        <PlanCard key={plan.id} plan={plan} isSelected={plan.id === selectedPlanId} onSelectPlan={onSelectPlan} />
      ))}
    </div>
  );
}
