import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PlanFeaturesListProps {
  features: { name: string; isAvailable: boolean }[];
}

export default function PlanFeaturesList({ features }: PlanFeaturesListProps) {
  return (
    <ul>
      {features.map((feature, index) => (
        <li key={index}>
          {feature.isAvailable ? (
            feature.name
          ) : (
            <span>
              <FontAwesomeIcon icon={faTimes} /> {feature.name}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
}
