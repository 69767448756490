import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Card, Form, ListGroup } from 'react-bootstrap'
import { Plan } from '../../PlanApiSlice/Representations/Plan'

const FeatureList = ({ features }: { features: Plan['features'] }) => {
  if (!features.length) return <div>No hay características disponibles</div>

  return features.map((feature, index) => (
    <ListGroup.Item key={index} className={!feature.isAvailable ? 'feature-unavailable' : ''}>
      {feature.isAvailable ? (
        feature.name
      ) : (
        <span>
          <FontAwesomeIcon icon={faTimes} /> {feature.name}
        </span>
      )}
    </ListGroup.Item>
  ))
}

export default function PlanCard({ plan, isSelected, onSelectPlan }: { plan: Plan; isSelected: boolean; onSelectPlan: (plan: Plan) => void }) {
  const [isAnnualPlan, setIsAnnualPlan] = useState(false)

  const togglePlanType = () => {
    setIsAnnualPlan((prev) => !prev)
  }

  const handleSelectPlan = () => {
    const selectedPlan = {
      ...plan,
      planType: isAnnualPlan ? 1 : 0,
    }
    onSelectPlan(selectedPlan)
  }

  //@ts-ignore

  const isSkeleton = plan.id === 'skeleton'

  return (
    <Card className={`mx-auto my-3 ${isSelected ? 'border-primary' : ''}`} style={{ width: '18rem' }}>
      {isSkeleton ? <div className="skeleton" style={{ height: '286px', width: '286px', borderRadius: '8px' }}></div> : <Card.Img variant="top" src={plan.image} />}

      <Card.Body>
        {isSkeleton ? <Card.Title className="skeleton" style={{ height: '1.5rem', width: '100%', borderRadius: '8px' }}></Card.Title> : <Card.Title>{plan.name}</Card.Title>}

        <div className="d-flex justify-content-center">
          {isSkeleton ? (
            <div className="skeleton" style={{ height: '1rem', width: '80px', borderRadius: '8px' }}></div>
          ) : (
            <Card.Text>
              <strong>{isAnnualPlan ? plan.annualPrice : plan.monthlyPrice}</strong> / {isAnnualPlan ? 'Anual' : 'Mensual'}
            </Card.Text>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-end">
          {isSkeleton ? <div className="skeleton" style={{ height: '1rem', width: '80px', borderRadius: '8px' }}></div> : <Form.Switch id={`custom-switch-${plan.name}`} label={isAnnualPlan ? 'Plan Anual' : 'Plan Mensual'} checked={isAnnualPlan} onChange={togglePlanType} />}
        </div>

        <ListGroup className="list-group-flush mb-3">
          {isSkeleton ? (
            <>
              <div className="skeleton" style={{ height: '1rem', width: '100%', borderRadius: '8px', marginTop: '10px' }}></div>
              <div className="skeleton" style={{ height: '1rem', width: '100%', borderRadius: '8px', marginTop: '10px' }}></div>
              <div className="skeleton" style={{ height: '1rem', width: '100%', borderRadius: '8px', marginTop: '10px' }}></div>
              <div className="skeleton" style={{ height: '1rem', width: '100%', borderRadius: '8px', marginTop: '10px' }}></div>
            </>
          ) : (
            <FeatureList features={plan.features} />
          )}
        </ListGroup>

        <div className="d-flex justify-content-center">
          {isSkeleton ? (
            <div className="skeleton" style={{ height: '2rem', width: '100px', borderRadius: '8px' }}></div>
          ) : (
            <button onClick={handleSelectPlan} className="btn btn-primary">
              {isSelected ? 'Seleccionado' : 'Escoger Plan'}
            </button>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}
