import React from 'react';
import ContactBreadCumb from '../components/contacts/ContactBreadCumb';
import NewsLetter from '../components/NewsLetter';
import ContactForm from '../components/contacts/ContactForm';

function ContactPage() {
  return (
    <main>
      <ContactBreadCumb />
      <ContactForm />
      <NewsLetter />
    </main>
  );
}

export default ContactPage;
