import PlanFeaturesList from '../PlanFeatureList';

export default function PlanSummary({ selectedPlan }: { selectedPlan: { planType?: number; annualPrice: number; monthlyPrice: number; image: string; name: string; features: { name: string; isAvailable: boolean }[] } }) {
  return (
    <>
      <h5>
        Plan {selectedPlan.planType === 1 ? 'Anual' : 'Mensual'}: {selectedPlan.planType === 1 ? selectedPlan.annualPrice : selectedPlan.monthlyPrice}
      </h5>

      <h5>Plan Seleccionado</h5>
      <img src={selectedPlan.image} alt={selectedPlan.name} style={{ width: '100%' }} />
      <PlanFeaturesList features={selectedPlan.features} />
    </>
  );
}
