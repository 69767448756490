import React from 'react';
import BreederBreadCumb from '../components/breeder/BreederBreadCumb';
import BreederAdoption from '../components/breeder/BreederAdoption';
import AdoptionPuppies from '../About/components/AdoptionPuppies';
import AdoptionFaq from '../About/components/AboutFaq';
import AdoptionGallery from '../About/components/AboutGallery';

function BreederPage() {
  return (
    <main>
      <BreederBreadCumb />
      <BreederAdoption />
      <AdoptionPuppies />
      <AdoptionFaq />
      <AdoptionGallery />
    </main>
  );
}

export default BreederPage;
