
export default function HomeCounter() {

  return (
    <div className="row justify-content-center">
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="counter-item">
          <h2 className="count">
            <span className="odometer">150</span>+
          </h2>
          <p>Mascotas registradas</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="counter-item">
          <h2 className="count">
            <span className="odometer">95</span>%
          </h2>
          <p>Usuarios satisfechos</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="counter-item">
          <h2 className="count">
            <span className="odometer">300</span>+
          </h2>
          <p>Veterinarios asociados</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="counter-item">
          <h2 className="count">
            <span className="odometer">200</span>+
          </h2>
          <p>Servicios disponibles</p>
        </div>
      </div>
    </div>
  );
}

