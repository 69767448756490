import Testimonial from '@/Home/Components/Testimonial'
import WelcomeModal from '../../Common/Components/WelcomModal'
import MainSlider from '../../components/MainSlider'
import PlanPage from '../../Plan/components/Plan'
import Counter from '../Components/Counter'
import Faq from '../Components/Faq'

function Home() {
  return (
    <main>
      <MainSlider />
      <Counter />
      <WelcomeModal />

      <PlanPage />
      {/* <Adoption />
      <BreedServices /> */}
      <Faq afterElment="faq-area" />
      {/* <Brand /> */}
      {/* <AdoptionShop /> */}
      <Testimonial />
      {/* <BlogsHome />
      <Newsletter /> */}
    </main>
  )
}

export default Home
