import { REFRESH_TOKEN, TOKEN } from '../../config/constants'
import { API_PATH_PREFIX, instance } from '../../config/fetcher'
import { deleteSecureItem } from '../../utils/Storage'
import { objectToAuthUser } from '../Representations/mappers/authUserMapper'

export const fetchUserWithAccessToken = async (accessToken: string) => {
  const response = await instance.get(`${API_PATH_PREFIX}/User/getUser`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  console.log('response', response)
  return objectToAuthUser(response.data)
}

export const refreshAccessToken = async (refreshToken: string, deviceId: string) => {
  const response = await instance.post(`${API_PATH_PREFIX}/User/refresh-token`, { refreshToken, deviceId }).then((res) => res.data)
  console.log('response', response)
  return response
}

export const clearTokensAndLogout = async () => {
  await deleteSecureItem(TOKEN)
  await deleteSecureItem(REFRESH_TOKEN)
}
