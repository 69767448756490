import { RootState } from '@/store/store'
import { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../../components/customButton'
import PetForm from '../../../Pet/Components/PetForm'
import { closePlanModal, openPaymentModal } from '../../../Plan/PlanApiSlice/PlanSlice'
import PlanSummary from '../PlanSummary'

export default function PlanModal() {
  const dispatch = useDispatch()
  const { selectedPlan, showPlanModal } = useSelector((state: RootState) => state.PlanSlice)
  //const { authUser } = useSelector((state: RootState) => state.AuthSlice)
  const [petType, setPetType] = useState('')
  const [petSize, setPetSize] = useState('')
  const [petName, setPetName] = useState('')
  const [petYear, setPetYear] = useState('')
  const [petBreed, setPetBreed] = useState('')

  useEffect(() => {
    setPetBreed('')
  }, [petType, petSize])

  const handleClose = () => {
    dispatch(closePlanModal())
  }

  const handleSavePet = async () => {
    // const petData = {
    //   name: petName,
    //   type: petType,
    //   size: petSize,
    //   yearOfBirth: petYear,
    //   breed: petBreed,
    //   owner: authUser?.userId,
    // }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 1000)
    })
  }

  const handlePayClick = async () => {
    try {
      const saveSuccess = await handleSavePet()
      if (saveSuccess) {
        dispatch(openPaymentModal())
      }
    } catch (error) {
      console.error('Error al guardar la mascota:', error)
    }
  }

  if (!selectedPlan) return null

  return (
    <>
      <Modal show={showPlanModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Resumen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>{selectedPlan && <PlanSummary selectedPlan={selectedPlan} />}</Col>

            <Col md={6}>
              <h5>Información de la Mascota</h5>
              <PetForm petName={petName} setPetName={setPetName} petYear={petYear} setPetYear={setPetYear} petBreed={petBreed} setPetBreed={setPetBreed} petType={petType} setPetType={setPetType} petSize={petSize} setPetSize={setPetSize} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton styleType="style-1" onClick={handleClose} animation="fadeIn" duration="0.3s">
            Cancelar
          </CustomButton>
          <CustomButton styleType="style-2" onClick={handlePayClick} animation="fadeIn" duration="0.3s">
            Añadir mascota
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}
