import User from '../User';

export type UserObject = {
  id: number;
  name: string;
  lastname: string;
  email: string;
  cellphone: string;
  address: string;
  onboarding: boolean;
  password: string;
  isSocialMedia: boolean;
  termsAccepted: boolean;
};
export function objectToUser(object: UserObject): User {
  return {
    id: object.id,
    name: object.name,
    surname: object.lastname,
    address: object.address,
    email: object.email,
    phone: object.cellphone,
    onboarding: object.onboarding,
    password: object.password,
    isSocialMedia: object.isSocialMedia,
    termsAccepted: object.termsAccepted,
  };
}
