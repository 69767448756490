import React from 'react';

function PetclubFaq() {
  return (
    <section className="faq-area faq-two-bg">
      <div className="faq-two-img" />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-7 col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title white-title mb-35">
                <h5 className="sub-title">Preguntas Frecuentes</h5>
                <h2 className="title">Conoce más sobre Petclub</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        ¿Qué es el carnet virtual de mi mascota?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                      El carnet virtual de Petclub es un documento digital que almacena la información médica de tu mascota, incluyendo vacunas, visitas al veterinario y tratamientos. Puedes acceder a él en cualquier momento desde tu perfil en la aplicación.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        ¿Cómo encuentro centros veterinarios en Petclub?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      Petclub te permite localizar centros veterinarios cercanos a tu ubicación. Simplemente ingresa a la sección de servicios y selecciona "Centros Veterinarios". Podrás ver una lista con información detallada de cada centro, incluyendo horarios y servicios disponibles.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        ¿Qué son los lugares petfriendly en Petclub?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">
                      Los lugares petfriendly son sitios donde las mascotas son bienvenidas. En Petclub, podrás encontrar restaurantes, parques y otros establecimientos que permiten la entrada de animales. Además, puedes filtrar según tu ubicación para encontrar los más cercanos.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        ¿Cómo puedo acceder a entrenadores para mi mascota?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div className="card-body">
                      Petclub ofrece una lista de entrenadores certificados para tu mascota. Simplemente ingresa a la sección de "Entrenadores" en la aplicación, donde podrás ver perfiles de entrenadores, sus especialidades, y contactarlos directamente para agendar una sesión.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        ¿Qué tipos de experiencias puedo encontrar en Petclub?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                    <div className="card-body">
                      En Petclub puedes encontrar experiencias como visitas a parques exclusivos para mascotas, eventos y actividades grupales para socialización, y recomendaciones de lugares ideales para disfrutar con tu mascota.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        ¿Petclub es gratuito?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                    <div className="card-body">
                      Sí, Petclub es gratuito para los usuarios. Algunas funciones premium, como acceso a servicios exclusivos o experiencias personalizadas, pueden tener un costo adicional. Puedes ver más información en la sección de "Planes" dentro de la app.
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape">
        <img src="img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
}

export default PetclubFaq;
