import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CustomButton from '../../components/customButton';

export default function GetAppPage() {
  const [selectedPlatform, setSelectedPlatform] = useState<'android' | 'ios' | null>(null);

  const androidUrl = 'https://play.google.com/store';
  const iosUrl = 'https://apps.apple.com';

  const handlePlatformSelection = (platform: 'android' | 'ios') => {
    setSelectedPlatform(platform);
  };

  const qrUrl = selectedPlatform === 'android' ? `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(androidUrl)}` : selectedPlatform === 'ios' ? `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(iosUrl)}` : null;

  return (
    <div className="get-app-page mb-150 mt-150">
      <Container className="text-center">
        <Row className="justify-content-center">
          <Col md={8}>
            <h1 className="get-app-title">¡Obtén nuestra App y lleva a tu mascota a otro nivel!</h1>
            <p className="get-app-subtitle">Disponible para Android y iOS. Escanea el código QR o descarga directamente de las tiendas.</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <CustomButton styleType="style-1" onClick={() => handlePlatformSelection('android')} animation="fadeIn" duration="0.3s">
              <FontAwesomeIcon icon={faAndroid} /> Descargar para Android
            </CustomButton>
          </Col>
          <Col xs="auto">
            <CustomButton styleType="style-1" onClick={() => handlePlatformSelection('ios')} animation="fadeIn" duration="0.3s">
              <FontAwesomeIcon icon={faApple} /> Descargar para iOS
            </CustomButton>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col md={8}>
            <p className="instruction-text">Haz clic en uno de los botones para generar el código QR.</p>
          </Col>
        </Row>
        {selectedPlatform && (
          <div className="text-center mt-4">
            <p>Escanea el código QR para descargar la aplicación en {selectedPlatform === 'android' ? 'Android' : 'iOS'}:</p>
            <img src={qrUrl!} alt={`QR Code ${selectedPlatform}`} className="mt-2" />
          </div>
        )}
      </Container>
    </div>
  );
}
