import axios from 'axios'
import { error } from 'console'
import { useEffect, useState } from 'react'

interface PaymentDetails {
  paymentId: string | null
  clientTransactionId: string | null
  ctoken: string | null
}

export default function PaymentConfirmation() {
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
    paymentId: null,
    clientTransactionId: null,
    ctoken: null,
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const paymentId = params.get('id')
    const clientTransactionId = params.get('clientTransactionId')
    const ctoken = params.get('ctoken')

    setPaymentDetails({ paymentId, clientTransactionId, ctoken })
  }, [])

  useEffect(() => {
    if (paymentDetails.paymentId && paymentDetails.clientTransactionId) {
      const confirmPayment = async () => {
        try {
          const tokenPayphone = process.env.REACT_APP_TOKEN_PAYPHONE

          const response = await axios.post(
            'https://pay.payphonetodoesposible.com/api/button/V2/Confirm',
            {
              id: Number(paymentDetails.paymentId),
              clientTxId: paymentDetails.clientTransactionId,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenPayphone}`,
              },
            }
          )
        } catch (error) {
          if (axios.isAxiosError(error)) {
          } else {
          }
        }
      }

      confirmPayment()
    }
  }, [paymentDetails])

  return (
    <div style={{ textAlign: 'center', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ fontSize: '100px', color: '#4caf50' }}>😊</div>
      <h1>¡Pago realizado con éxito!</h1>
      <div style={{ marginTop: '20px', fontSize: '18px' }}>
        {paymentDetails.paymentId && paymentDetails.clientTransactionId ? (
          <div>
            <p>
              <strong>ID de Transacción:</strong> {paymentDetails.paymentId}
            </p>
            <p>
              <strong>ID Único del Cliente:</strong> {paymentDetails.clientTransactionId}
            </p>
            <p>
              <strong>Token de Confirmación:</strong> {paymentDetails.ctoken}
            </p>
          </div>
        ) : (
          <p>No se encontraron detalles de la transacción.</p>
        )}
      </div>
    </div>
  )
}
