import HomeStatics from '../HomeStatics'

export interface HomeStaticsInput {
  EstablishmentRegistered: number
  EstablishmentType1: number
  EstablishmentType2: number
  TotalPets: number
  TotalUsers: number
}

export function objectToHomeStatics(object: HomeStaticsInput): HomeStatics {
  return {
    EstablishmentRegistered: object.EstablishmentRegistered,
    EstablishmentType1: object.EstablishmentType1,
    EstablishmentType2: object.EstablishmentType2,
    TotalPets: object.TotalPets,
    TotalUsers: object.TotalUsers,
  }
}
