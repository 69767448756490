import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Form as BootstrapForm, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomButton from '../../components/customButton';
import { AppDispatch, RootState } from '../../store/store';
import { AuthState } from '../AuthApiSlice/AuthSlice';
import { loginActions } from '../AuthApiSlice/loginActions';
interface LoginFormValues {
  email: string;
  password: string;
}

export default function LoginPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = loginActions();

  const redirectTo = (location.state as any)?.redirectTo || '/';

  const authState = useSelector((state: RootState) => state.AuthSlice as AuthState);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [alertClass, setAlertClass] = useState<string>('');

  const validationSchema = Yup.object({
    email: Yup.string().email('Email inválido').required('El email es obligatorio'),
    password: Yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres').required('La contraseña es obligatoria'),
  });

  const handleLogin = (values: LoginFormValues, { setSubmitting }: FormikHelpers<LoginFormValues>) => {
    dispatch(login(values));
    setSubmitting(false);
  };

  const isAuthenticated = useSelector((state: RootState) => state.AuthSlice.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (authState.error) {
      setErrorMessage(authState.error);
      setAlertClass('alert-fade-in');
    } else if (authState.authUser) {
      setSuccessMessage('¡Inicio de sesión exitoso!');
      setAlertClass('alert-fade-in');
      navigate(redirectTo);
    }
  }, [authState.error, authState.authUser]);

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setAlertClass('alert-fade-out');

        setTimeout(() => {
          setErrorMessage(null);
          setSuccessMessage(null);
          setAlertClass('');
        }, 500);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  return (
    <Container fluid className="d-flex justify-content-center align-items-center mb-75 mt-100">
      <Row className="w-100 justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <div className="position-relative">
            {errorMessage && (
              <Alert variant="danger" className={`text-center ${alertClass} position-absolute w-100`} style={{ top: '-60px' }}>
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert variant="success" className={`text-center ${alertClass} position-absolute w-100`} style={{ top: '-60px' }}>
                {successMessage}
              </Alert>
            )}
            <Card className="shadow">
              <Card.Body>
                <h1 className="text-center mb-4">Iniciar Sesión</h1>

                <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} onSubmit={handleLogin}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <BootstrapForm.Group controlId="formEmail">
                        <BootstrapForm.Label>Email</BootstrapForm.Label>
                        <BootstrapForm.Control type="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.email && touched.email} />
                        <BootstrapForm.Control.Feedback type="invalid">{errors.email}</BootstrapForm.Control.Feedback>
                      </BootstrapForm.Group>

                      <BootstrapForm.Group controlId="formPassword" className="mt-3">
                        <BootstrapForm.Label>Contraseña</BootstrapForm.Label>
                        <BootstrapForm.Control type="password" name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.password && touched.password} />
                        <BootstrapForm.Control.Feedback type="invalid">{errors.password}</BootstrapForm.Control.Feedback>
                      </BootstrapForm.Group>

                      <div className="mt-4 d-grid">
                        <CustomButton styleType="style-1" onClick={handleSubmit} disabled={isSubmitting || authState.isLoading}>
                          {authState.isLoading ? (
                            <>
                              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Iniciando sesión...
                            </>
                          ) : (
                            'Iniciar sesión'
                          )}
                        </CustomButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
