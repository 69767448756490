import AboutBreadcumb from '../components/AboutBreadcumb';
import AboutFaq from '../components/AboutFaq';
import AdoptionGallery from '../components/AboutGallery';
import AdoptionPuppies from '../components/AdoptionPuppies';
import AdoptionSlider from '../components/AboutSlider';

export default function AboutPage() {
  return (
    <main>
      <AboutBreadcumb />
      <AdoptionSlider />
      {/* <AdoptionPuppies /> */}
      <AboutFaq />
      <AdoptionGallery /> 
    </main>
  );
}
