import React from 'react';
import Slider from 'react-slick';

function PetclubGallery() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="breeder-gallery-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <Slider className="breeder-gallery-active" {...settings}>
              <div className="breeder-gallery-item">
                <img src="img/images/breeder_gallery01.jpg" alt="Galería de mascotas" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/breeder_gallery02.jpg" alt="Galería de mascotas" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/breeder_gallery03.jpg" alt="Galería de mascotas" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/breeder_gallery04.jpg" alt="Galería de mascotas" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-6">
            <div className="adoption-content">
              <h5 className="sub-title">Acerca de Petclub</h5>
              <h2 className="title">
                Trabajamos para el <span>Bienestar</span> <br /> de tu Mascota
              </h2>
              <p>
                Petclub no solo te permite llevar un registro de la salud y bienestar de tu mascota, sino que también ofrece la opción de reportar y encontrar mascotas perdidas. Con nuestra plataforma, puedes publicar información sobre tu mascota perdida y recibir notificaciones
                en caso de que sea encontrada.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> Carnet virtual para tu mascota
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Reporte y búsqueda de mascotas perdidas
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PetclubGallery;
