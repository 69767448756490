import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { REFRESH_TOKEN, TOKEN } from '../../config/constants'
import { API_PATH_PREFIX, instance } from '../../config/fetcher'
import { getDeviceId } from '../../utils/DeviceId'
import { setSecuredItem } from '../../utils/Storage'
import AuthUser from '../Representations/AuthUser'
import { fetchUserWithAccessToken } from '../Utils/AuthUtils'
import { AuthState } from './AuthSlice'
export function loginActions() {
  const login = createAsyncThunk('auth/login', async (credentials: { email: string; password: string }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { email, password } = credentials
    const deviceId = await getDeviceId()

    try {
      const response = await instance.post(`${API_PATH_PREFIX}/User/login`, { email, password, deviceId })

      const { Success, Message, AccessToken, RefreshToken } = response.data

      if (!Success) {
        return rejectWithValue(Message || 'Error desconocido')
      }

      await setSecuredItem(TOKEN, AccessToken)
      await setSecuredItem(REFRESH_TOKEN, RefreshToken)

      const user = await fetchUserWithAccessToken(AccessToken)
      return { user, isAuthenticated: true }
    } catch (error: any) {
      console.log('Error en login', error.data.Message)
      return rejectWithValue('Error desconocido')
    }
  })

  const handlePending = (state: AuthState) => {
    state.isLoading = true
    state.error = null
  }

  const handleFulfilled = (state: AuthState, action: PayloadAction<{ user: AuthUser; isAuthenticated: boolean }>) => {
    state.isLoading = false
    state.isAuthenticated = action.payload.isAuthenticated
    state.authUser = action.payload.user
    state.error = null
  }

  const handleRejected = (state: AuthState, action: PayloadAction<string>) => {
    state.isLoading = false
    state.error = action.payload
    state.isAuthenticated = false
    state.authUser = null
  }

  return { login, handlePending, handleFulfilled, handleRejected }
}
