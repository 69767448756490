import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { Api } from '../config/fetcher';
import AuthSlice from '../Auth/AuthApiSlice/AuthSlice';
import PlanSlice from '../Plan/PlanApiSlice/PlanSlice';
import PaymentApiSlice from '../Payment/PaymentApiSlice/PaymentSlice';

const store = configureStore({
  reducer: {
    [Api.reducerPath]: Api.reducer,
    AuthSlice,
    PlanSlice,
    PaymentApiSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([Api.middleware]),
  devTools: true,
});

setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
