import { closePaymentModal } from '../../Plan/PlanApiSlice/PlanSlice';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentState {
  isProcessing: boolean;
  paymentSuccess: boolean;
  error: string | null;
}

const initialState: PaymentState = {
  isProcessing: false,
  paymentSuccess: false,
  error: null,
};

export const processPayment = createAsyncThunk('payment/processPayment', async (paymentData: { cardNumber: string; expiryDate: string; cvv: string; cardholderName: string; amount: string }, { rejectWithValue }) => {
  try {
    // const response = instance.post('/payments/process', paymentData);
    const response = 'ok';
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || 'Error procesando el pago');
  }
});

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPaymentState: (state) => {
      state.isProcessing = false;
      state.paymentSuccess = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(processPayment.pending, (state) => {
      state.isProcessing = true;
      state.paymentSuccess = false;
      state.error = null;
    });
    builder.addCase(processPayment.fulfilled, (state) => {
      state.isProcessing = false;
      state.paymentSuccess = true;
      state.error = null;
    });
    builder.addCase(processPayment.rejected, (state, action: PayloadAction<any>) => {
      state.isProcessing = false;
      state.paymentSuccess = false;
      state.error = action.payload || 'Error desconocido';
    });
    builder.addCase(closePaymentModal, (state) => {
      state.isProcessing = false;
      state.paymentSuccess = false;
      state.error = null;
    });
  },
});

export const { resetPaymentState } = paymentSlice.actions;
export default paymentSlice.reducer;
