import axios from 'axios';
import { Plan } from '../PlanApiSlice/Representations/Plan';
import apiConfig from '../../config/apiConfig';

const getPlans = async (): Promise<Plan[]> => {
  try {
    const response = await axios.get(`${apiConfig.baseURL}api/Plan/GetPlans`);

    return response.data as Plan[];
  } catch (error) {
    throw error;
  }
};

export default getPlans;
