import { useState } from 'react'

export default function Faq({ afterElment }: any) {
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  return (
    <section className={`${afterElment} faq-bg`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-img-wrap">
              <img src="img/images/faq_tv.png" className="img-frame" alt="" />
              <img src="img/images/faq_img.png" className="main-img" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title mb-35">
                <h5 className="sub-title">Preguntas Frecuentes</h5>
                <h2 className="title">Historia & Adopción Familiar</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        ¿Cómo ayuda Petclub a encontrar mascotas perdidas?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div className="card-body">Petclub ofrece una funcionalidad de alerta de mascotas perdidas, donde los usuarios pueden publicar información sobre sus mascotas extraviadas y la comunidad puede ayudar a localizarlas.</div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        ¿Qué servicios ofrece Petclub a los usuarios?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">Petclub ofrece una variedad de servicios, incluyendo veterinarios certificados, carnet virtual para tus mascotas, y la posibilidad de encontrar lugares pet-friendly.</div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        ¿Cuántos meses debe tener un cachorro para adoptar?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">Los cachorros deben tener al menos 3 meses de edad para ser adoptados y deben tener su carnet de vacunación actualizado, algo que Petclub te ayuda a gestionar fácilmente.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape">
        <img src="img/images/faq_shape.png" alt="" />
      </div>
    </section>
  )
}
