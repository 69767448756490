import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

interface FormData {
  nombre: string;
  apellido: string;
  correo: string;
  razon?: string;
}

export default function DeleteAccount() {
  const [formData, setFormData] = useState<FormData>({
    nombre: '',
    apellido: '',
    correo: '',
    razon: ''
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.nombre || !formData.apellido || !formData.correo) {
      setErrorMessage('Por favor, complete todos los campos obligatorios.');
      return;
    }

    setErrorMessage(null);

    // Simulación de envío exitoso
    setTimeout(() => {
      setSuccessMessage('Solicitud enviada exitosamente.');
    }, 1000);

    // Reseteo del formulario
    setFormData({
      nombre: '',
      apellido: '',
      correo: '',
      razon: ''
    });
  };

  return (
    <div className="container">
      <h2 className="mt-4">Solicitud de eliminar cuenta</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formNombre">
          <Form.Label>Nombre *</Form.Label>
          <Form.Control
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formApellido" className="mt-3">
          <Form.Label>Apellido *</Form.Label>
          <Form.Control
            type="text"
            name="apellido"
            value={formData.apellido}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formCorreo" className="mt-3">
          <Form.Label>Correo electrónico *</Form.Label>
          <Form.Control
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formRazon" className="mt-3">
          <Form.Label>Razón para solicitar la eliminación (opcional)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="razon"
            value={formData.razon}
            onChange={handleChange}
          />
        </Form.Group>

        {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
        {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}

        <Button variant="primary" type="submit" className="mt-4">
          Enviar solicitud
        </Button>
      </Form>

      <p className="mt-4">
        Al enviar esta solicitud, comprendes que todos tus datos serán eliminados permanentemente
        y no se podrán recuperar. Asegúrate de que deseas proceder.
      </p>
    </div>
  );
}
