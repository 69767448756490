import React from 'react';
import BreederDetBread from '../components/breederDetails/BreederDetBread';
import BreederContent from '../components/breederDetails/BreederContent';
import AdoptionFaq from '../About/components/AboutFaq';
import AdoptionPuppies from '../About/components/AdoptionPuppies';
function BreederDetailsPage() {
  return (
    <main>
      <BreederDetBread />
      <BreederContent />
      <AdoptionFaq />
      <AdoptionPuppies />
    </main>
  );
}

export default BreederDetailsPage;
