import Slider from 'react-slick';

function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="testimonial-area testimonial-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Testimonios</h5>
              <h2 className="title">Nuestros Clientes Felices</h2>
              <p>En Petclub, trabajamos por el bienestar de tu mascota. Con nuestro carnet virtual y servicios veterinarios, gestionar el cuidado de tu peludo nunca ha sido tan fácil. ¡Descubre por qué nuestros usuarios están encantados!</p>
            </div>
          </div>
        </div>
        <Slider className="row testimonial-active" {...settings}>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“Gracias a Petclub, ahora puedo llevar un control más preciso de las citas veterinarias de mi perro. El carnet virtual es súper práctico y me recuerda cuándo debo llevar a Toby al veterinario.”</p>
                <div className="testi-avatar-info">
                  <h5 className="title">María Fernanda Sánchez</h5>
                  <span>Guayaquil, Ecuador</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“Mis gatos ahora están mejor cuidados gracias a Petclub. Me encanta poder encontrar lugares petfriendly en Quito para ir con mis dos peludos. Además, el servicio de veterinario a domicilio ha sido de gran ayuda.”</p>
                <div className="testi-avatar-info">
                  <h5 className="title">Carlos Vélez</h5>
                  <span>Quito, Ecuador</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“Desde que empecé a usar Petclub, tengo acceso fácil a veterinarios certificados en Cuenca, y puedo agendar las citas de mis mascotas sin complicaciones. El carnet virtual es súper útil para llevar un registro de sus vacunas.”</p>
                <div className="testi-avatar-info">
                  <h5 className="title">Andrea Castillo</h5>
                  <span>Quito, Ecuador</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“Lo mejor de Petclub es que me permite encontrar todo lo que necesito para mi perro en un solo lugar. El servicio de veterinario a domicilio ha sido un salvavidas para mí y para mi mascota.”</p>
                <div className="testi-avatar-info">
                  <h5 className="title">Juan Pablo Ortega</h5>
                  <span>Quito, Ecuador</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“Lo mejor de Petclub es que me permite encontrar todo lo que necesito para mi perro en un solo lugar. El servicio de veterinario a domicilio ha sido un salvavidas para mí y para mi mascota.”</p>
                <div className="testi-avatar-info">
                  <h5 className="title">Juan Pablo Ortega</h5>
                  <span>Quito, Ecuador</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>“Lo mejor de Petclub es que me permite encontrar todo lo que necesito para mi perro en un solo lugar. El servicio de veterinario a domicilio ha sido un salvavidas para mí y para mi mascota.”</p>
                <div className="testi-avatar-info">
                  <h5 className="title">Juan Pablo Ortega</h5>
                  <span>Quito, Ecuador</span>
                </div>
              </div>
            </div>
          </div>
        </Slider>

      </div>
    </section>
  );
}

export default Testimonial;
