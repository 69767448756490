import { Card } from 'react-bootstrap';

export default function PrivacyPolicies() {
  return (
    <div className="d-flex mb-100 mt-100 justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Card style={{ width: '80%', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Card.Body>
          <Card.Title className="text-center">Aviso de la Política de Privacidad</Card.Title>
          <Card.Text>
            Comprometidos con la transparencia en el tratamiento de sus datos personales damos a conocer el aviso de política de privacidad que tiene la finalidad de informar a los titulares de los datos de PETCLUB, que administramos bases de datos en las que constan sus datos
            personales, por lo cual ponemos a su disposición información respecto del tratamiento o uso al que se someterán sus datos personales, así como las medidas de protección y respeto a sus derechos.
          </Card.Text>
          <Card.Subtitle className="mb-2 mt-4">Avisos Legales</Card.Subtitle>
          <Card.Text>
            Da su consentimiento, libre, específico, informado e inequívoco para el tratamiento de sus datos personales por parte de PETCLUB.
            <br />
            Para el correcto funcionamiento del servicio, autoriza explícitamente a PETCLUB a transferir o encargar sus datos personales a un tercero localizado dentro o fuera del país para el cumplimiento de objetivos del uso del servicio.
            <br />
            PETCLUB se reserva el derecho de realizar adiciones, supresiones o modificaciones del contenido de la Plataforma en cualquier momento sin notificación previa.
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Responsable del tratamiento</Card.Subtitle>
          <Card.Text>
            PETCLUB SAS, con RUC 0601255276001 y domicilio fiscal en Urb. San Eduardo, Psj C N66-36 y Av Los Eucaliptos, será el Responsable del tratamiento de datos personales. PETCLUB será quien recolectará, almacenará y tratará los datos personales, así como también realizar
            encargos de los mismos de acuerdo con lo establecido en la Ley Orgánica de Protección de Datos Personales del Ecuador (en adelante LOPDP).
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Finalidades del tratamiento de datos personales</Card.Subtitle>
          <Card.Text>
            PETCLUB tratará los datos personales de acuerdo a las siguientes finalidades:
            <ul>
              <li>Gestionar la relación contractual de los productos y servicios que el titular o interesado solicite o contrate con PETCLUB.</li>
              <li>Cumplir con las obligaciones legales y normativa aplicable a PETCLUB.</li>
              <li>Consultar a las entidades de información crediticia.</li>
              <li>Prevenir el fraude.</li>
              <li>Realizar análisis de riesgos y finalidades comerciales, elaboración de perfiles comerciales y de riesgos.</li>
              <li>Ofertas de productos y servicios de PETCLUB.</li>
              <li>Generación y gestión de modelos de análisis.</li>
              <li>Comunicar sus datos a los socios comerciales para que puedan ofrecer sus propios productos y servicios personalizados.</li>
              <li>Si usted es un representante, garante, beneficiario, cónyuge del titular o interesado que emitió el consentimiento, trataremos sus datos personales en PETCLUB para la gestión del contrato en el que intervenga.</li>
              <li>Verificar la identidad del usuario para acceso al servicio.</li>
              <li>Mejorar, desarrollar y comercializar el servicio, conforme el interés legítimo de PETCLUB.</li>
              <li>Responder a solicitudes realizadas por usuarios.</li>
              <li>Cumplir los Términos y Condiciones del servicio, así como la finalidad de la misma.</li>
              <li>Para analizar preferencias de consumos mediante datos para elaborar perfiles y hábitos.</li>
            </ul>
            Recopilamos y recibimos información de los diferentes dispositivos en los que se utiliza nuestra plataforma la cual es utilizada para mejorar la aplicación, los productos y otros servicios, permitiendo personalizar la experiencia del usuario en la aplicación. La
            información de dispositivos que recopilamos y recibimos incluye lo siguiente:
            <ul>
              <li>El dispositivo y el software utilizado, y otras características del dispositivo, frecuencia de uso de la aplicación y desde dónde se descargó.</li>
              <li>
                Direcciones IP para conocer la ubicación general y poder enviar información sobre nuevos productos y promociones especiales a sus usuarios, de acuerdo con su ubicación geográfica, mediante notificaciones push. El usuario podrá apagar el GPS o las notificaciones
                push en su teléfono. Sin embargo, si bloquea estas herramientas, algunas de las funcionalidades de la aplicación podrían dejar de funcionar adecuadamente.
              </li>
              <li>Información proporcionada directamente por el usuario como nombres, apellidos, número de identificación, número de teléfono, correo electrónico, sexo y edad. Así como la actividad de los usuarios en nuestros productos y eventos.</li>
            </ul>
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Base legal de tratamiento de los datos personales</Card.Subtitle>
          <Card.Text>
            El tratamiento de datos personales realizado por PETCLUB es lícito y legítimo conforme lo previsto en:
            <ol>
              <li>Por consentimiento del titular para una o varias finalidades.</li>
              <li>Que sea realizado en cumplimiento de una obligación legal.</li>
              <li>En cumplimiento de orden judicial, debiendo observarse los principios de la presente ley.</li>
              <li>Para la ejecución de medidas precontractuales a petición del titular o para el cumplimiento de obligaciones contractuales perseguidas por el responsable, encargado o un tercero legalmente habilitado.</li>
              <li>Para tratamiento de datos personales que consten en bases de datos de acceso público.</li>
              <li>Para satisfacer un interés legítimo del responsable de tratamiento o de un tercero, siempre que no prevalezca el interés o derechos fundamentales de los titulares al amparo de lo dispuesto en esta norma.</li>
            </ol>
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Transferencia de Datos Personales nacionales e internacionales</Card.Subtitle>
          <Card.Text>
            PETCLUB de forma legítima podrá transferir dentro del país o fuera de sus fronteras a encargados del tratamiento, proveedores, clientes, franquicias y organismos reguladores. Adicionalmente, PETCLUB podrá encargar los datos personales del titular o interesado a
            organismos de control que lo requieran conforme la normativa aplicable. PETCLUB exigirá a los terceros a quienes se transfieran o encarguen sus datos personales el cumplimiento de estándares adecuados de confidencialidad, protección y seguridad de éstos, y
            especialmente cuando dichos terceros se encuentren en países que no cuentan con una legislación de protección de datos adecuada conforme los parámetros establecidos por la normativa aplicable en cada jurisdicción. Para mayor información, contactar al Delegado de
            Protección de Datos Personales de PETCLUB en la siguiente dirección de correo electrónico: privacy@petclub.com.ec
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Derechos del Titular o interesado</Card.Subtitle>
          <Card.Text>
            El titular de los datos personales podrá ejercer los derechos de acceso, rectificación, eliminación, oposición, suspensión, portabilidad, y objetar decisiones automatizadas. Para hacer efectivos sus derechos, puede contactarnos en{' '}
            <a href="mailto:desarrollo.petclub@gmail.com">desarrollo.petclub@gmail.com</a>.
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Principios para el Tratamiento de Datos Personales en PETCLUB</Card.Subtitle>
          <Card.Text> PETCLUB utiliza los trece (13) principios definidos en la LOPDP, para realizar cualquier tratamiento de datos personales del titular o interesado. Por ejemplo: transparencia, confidencialidad, seguridad, pertenencia y minimización.</Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Seguridad y Protección de Datos</Card.Subtitle>
          <Card.Text>
            PETCLUB custodia los datos con los más altos estándares de seguridad de la industria financiera, para lo cual PETCLUB garantiza la integridad, confidencialidad y disponibilidad de sus procedimientos y tecnología e información. Adicionalmente, PETCLUB utiliza medidas
            de seguridad destinadas a custodiar los datos personales, y exigimos que los empleados de PETCLUB que tratan estos datos personales lo hagan de manera confidencial e íntegra, y con el mayor respeto a la intimidad de las personas.
          </Card.Text>

          <Card.Subtitle className="mb-2 mt-4">Cambios a la Política de Privacidad</Card.Subtitle>
          <Card.Text>La actualización de este aviso de privacidad será notificada a través del sitio web de PETCLUB o por medio de otros canales definidos por PETCLUB.</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
