import React from 'react';
import ShopArea from '../components/ourshop/ShopArea';
import ShopBreadcumb from '../components/ourshop/ShopBreadcumb';

function ShopPages() {
  return (
    <main>
      <ShopBreadcumb />
      <ShopArea />
    </main>
  );
}
export default ShopPages;
