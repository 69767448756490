import Cookies from 'js-cookie';

export const setSecuredItem = (key: string, value: string) => {
  try {
    Cookies.set(key, value, {
      expires: 7,
      secure: true,
      sameSite: 'Strict',
    });
  } catch (error) {
    console.error('🚀 ~ setSecuredItem ~ error:', error);
  }
};

export const getSecuredItem = (key: string) => {
  try {
    return Cookies.get(key) || null;
  } catch (error) {
    console.error('🚀 ~ getSecuredItem ~ error:', error);
  }
};

export const deleteSecureItem = (key: string) => {
  try {
    Cookies.remove(key);
  } catch (error) {
    console.error('🚀 ~ deleteSecureItem ~ error:', error);
  }
};
