import CustomButton from '../customButton';
import ContactAddress from './ContactAddress';

function ContactForm() {
  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                <h5 className="sub-title">Contáctanos</h5>
                <h2 className="title">
                  Hablemos<span>.</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <p>El perro doméstico es un descendiente domesticado del lobo. El perro se derivó de un lobo antiguo y extinto, y el lobo gris moderno.</p>
                <form className="contact-form">
                  <div className="form-grp">
                    <label htmlFor="name">
                      Tu Nombre <span>*</span>
                    </label>
                    <input type="text" id="name" placeholder="Juan Pérez..." />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="email">
                      Tu Correo Electrónico <span>*</span>
                    </label>
                    <input type="text" id="email" placeholder="info.ejemplo@correo.com" />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="message">
                      Tu Mensaje <span>*</span>
                    </label>
                    <textarea name="message" id="message" placeholder="Escribe tu mensaje..." defaultValue={''} />
                  </div>
                  <div className="form-grp checkbox-grp">
                    <input type="checkbox" id="checkbox" />
                    <label htmlFor="checkbox">No mostrar tu dirección de correo electrónico</label>
                  </div>
                  <CustomButton styleType="style-1" onClick={() => console.log("Botón clicado")}>
                    Enviar Ahora
                  </CustomButton>

                </form>
              </div>
            </div>

            <ContactAddress />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
