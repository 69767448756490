import axios from 'axios';
import Pet from '../Representations/Pet';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchRaces = async (type: number, size: string) => {
  try {
    const response = await axios.get(`${API_URL}api/PetBreed/GetAllByTypeAndSize/${type}/${size}`);

    return response.data.map((race: { name: string }) => ({ label: race.name, value: race.name }));
  } catch (error) {
    throw error;
  }
};

export const addPet = async (petData: any) => {
  try {
    const response = await axios.post(`${API_URL}api/Pet/addPet`, petData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al registrar la mascota:', error);
    throw error;
  }
};

export const editPet = async (petData: Pet) => {
  try {
    const response = await axios.put(`${API_URL}api/Pet/updatePet`, petData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.error('Error al registrar la mascota:');
    throw error;
  }
};
