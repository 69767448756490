import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

interface ButtonProps {
  to?: string;
  styleType: 'style-1' | 'style-2';
  children: React.ReactNode;
  onClick?: () => void;
  animation?: string;
  delay?: string;
  duration?: string;
  disabled?: boolean;
}

export default function CustomButton({ to, styleType, children, onClick, animation, delay, duration, disabled }: ButtonProps) {
  const styleClass = styleType === 'style-1' ? 'btn-style-1' : 'btn-style-2';

  if (onClick) {
    return (
      <button className={`btn ${styleClass}`} onClick={onClick} data-animation={animation} data-delay={delay} data-duration={duration} disabled={disabled}>
        <span>{children}</span>
        <img src="img/icon/w_pawprint.png" alt="Pawprint" className="btn-icon" />
      </button>
    );
  }

  if (to) {
    return (
      <Link to={to} className={`btn ${styleClass}`} data-animation={animation} data-delay={delay} data-duration={duration}>
        <span>{children}</span>
        <img src="img/icon/w_pawprint.png" alt="Pawprint" className="btn-icon" />
      </Link>
    );
  }

  return (
    <button className={`btn ${styleClass}`} data-animation={animation} data-delay={delay} data-duration={duration} disabled={disabled}>
      <span>{children}</span>
      <img src="img/icon/w_pawprint.png" alt="Pawprint" className="btn-icon" />
    </button>
  );
}
