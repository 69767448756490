import { API_PATH_PREFIX, instance } from '../../config/fetcher'
import HomeStatics from '../Representations/HomeStatics'
import { HomeStaticsInput, objectToHomeStatics } from '../Representations/Mappers/homeStaticsMapper'

export const getStaticsHome = async (): Promise<HomeStatics> => {
  try {
    const response = await instance.get(`${API_PATH_PREFIX}/Establishment/GetTotalStatistics`)
    const mappedData = objectToHomeStatics(response.data as HomeStaticsInput)
    return mappedData
  } catch (error) {
    console.error('Error fetching home statistics:', error)
    throw error
  }
}
