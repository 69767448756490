import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

interface PaymentFormProps {
  planInfo: {
    planType: string
    planPrice: string
    cardNumber: string
    expiryDate: string
    cvv: string
    cardholderName: string
  }
  isSubmitting: boolean
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}
export default function PaymentForm({ planInfo, isSubmitting, handleInputChange, handleSubmit }: PaymentFormProps) {
  return (
    <Form onSubmit={handleSubmit}>
      <p>
        Estás pagando el plan <strong>{planInfo.planType === 'Monthly' ? 'Mensual' : 'Anual'}</strong>, por un precio de <strong>${planInfo.planPrice}</strong>.
      </p>
      <Form.Group className="mb-3">
        <Form.Label>Número de Tarjeta de Crédito</Form.Label>
        <Form.Control type="text" placeholder="1234 5678 9101 1121" name="cardNumber" value={planInfo.cardNumber} onChange={handleInputChange} />
      </Form.Group>
      <Row>
        <Col md="6">
          <Form.Group>
            <Form.Label>Fecha de Expiración</Form.Label>
            <Form.Control type="text" placeholder="MM/AA" name="expiryDate" value={planInfo.expiryDate} onChange={handleInputChange} />
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Label>CVV</Form.Label>
            <Form.Control type="text" placeholder="CVV" name="cvv" value={planInfo.cvv} onChange={handleInputChange} />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Form.Label>Nombre del Titular</Form.Label>
        <Form.Control type="text" placeholder="Nombre tal como aparece en la tarjeta" name="cardholderName" value={planInfo.cardholderName} onChange={handleInputChange} />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Procesando...' : 'Proceder al Pago'}
      </Button>
    </Form>
  )
}
