import { RootState } from '@/store/store'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../../Auth/AuthApiSlice/AuthSlice'
import CustomButton from '../../../components/customButton'
import logo from '../../../img/logo/logoAlt.png'

function Header() {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: RootState) => state.AuthSlice.isAuthenticated)
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen)
    if (!menuOpen) {
      document.body.classList.add('mobile-menu-visible')
    } else {
      document.body.classList.remove('mobile-menu-visible')
    }
  }

  const closeMobileMenu = () => {
    setMenuOpen(false)
    document.body.classList.remove('mobile-menu-visible')
  }

  const handleLogout = () => {
    dispatch(logout(menuOpen))
    closeMobileMenu()
  }

  return (
    <header>
      <div className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              {/* Botón del menú de hamburguesa */}
              <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      width: '7rem',
                      height: 'auto',
                      margin: '20px',
                      opacity: 0.9,
                      backgroundColor: 'salmon',
                      padding: '10px',
                      borderRadius: '15px',
                    }}
                  />
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className="home-link">
                        <Link to="/">Inicio</Link>
                      </li>
                      <li className="about-link">
                        <Link to="/about">Sobre Nosotros</Link>
                      </li>
                      <li className="contacts-link">
                        <Link to="/contacts">Contáctanos</Link>
                      </li>
                      <li className="app-link">
                        <Link to="/get-app">Obtener Aplicacion</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-btn">
                        {isAuthenticated ? (
                          <CustomButton onClick={handleLogout} styleType="style-1">
                            Cerrar Sesión
                          </CustomButton>
                        ) : (
                          <CustomButton to="/login" styleType="style-1">
                            Iniciar Sesión
                          </CustomButton>
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* Menú móvil */}
              <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
                <div className="menu-backdrop" onClick={closeMobileMenu}></div>
                <nav className="menu-box">
                  <div className="close-btn" onClick={closeMobileMenu}>
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/" onClick={closeMobileMenu}>
                      <img src={logo} alt="Logo" />
                    </Link>
                  </div>
                  <ul className="menu-outer">
                    <li>
                      <Link to="/" onClick={closeMobileMenu}>
                        Inicio
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={closeMobileMenu}>
                        Sobre Nosotros
                      </Link>
                    </li>
                    <li>
                      <Link to="/get-app" onClick={closeMobileMenu}>
                        Obtener Aplicación
                      </Link>
                    </li>
                    <li>
                      <Link to="/contacts" onClick={closeMobileMenu}>
                        Contáctanos
                      </Link>
                    </li>
                    <li>
                      {isAuthenticated ? (
                        <Link to="/" onClick={handleLogout}>
                          Cerrar Sesión
                        </Link>
                      ) : (
                        <Link to="/login" onClick={closeMobileMenu}>
                          Iniciar Sesión
                        </Link>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="header-shape" style={{ backgroundImage: "url('img/bg/header_shape.png')" }} />
      </div>
    </header>
  )
}

export default Header
