import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery'
import ReactDOM from 'react-dom/client'
import App from './App'

const rootElement = document.getElementById('root')
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)

  $(document).ready(function () {
    $('#preloader').delay(0).fadeOut()
  })
} else {
  console.error('Root element not found')
}
