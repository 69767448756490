import AuthUser from '../AuthUser';
import { UserObject, objectToUser } from './userMapper';

type AuthUserObject = UserObject & {
  Token: string;
};

export function objectToAuthUser(object: AuthUserObject): AuthUser {
  return {
    userId: object.id,
    fullName: `${object.name} ${object.lastname}`,
    user: objectToUser(object),
    token: { accessToken: object.Token },
  };
}
