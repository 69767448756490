import React from 'react';
import BlogBreadcumb from '../components/blogs/BlogBreadcumb';
import BlogContent from '../components/blogs/BlogContent';

function BlogPage() {
  return (
    <main>
      <BlogBreadcumb />
      <BlogContent />
    </main>
  );
}
export default BlogPage;
