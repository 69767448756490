import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plan } from './Representations/Plan';
import { resetPaymentState } from '../../Payment/PaymentApiSlice/PaymentSlice';
interface PlanState {
  selectedPlan: Plan | null;
  showPlanModal: boolean;
  showPaymentModal: boolean;
}

const initialState: PlanState = {
  selectedPlan: null,
  showPlanModal: false,
  showPaymentModal: false,
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    selectPlan: (state, action: PayloadAction<Plan>) => {
      state.selectedPlan = action.payload;
      state.showPlanModal = true;
      state.showPaymentModal = false;
    },
    closePlanModal: (state) => {
      state.showPlanModal = false;
    },
    openPaymentModal: (state) => {
      state.showPlanModal = false;
      state.showPaymentModal = true;
    },
    closePaymentModal: (state) => {
      state.showPaymentModal = false;
    },
    resetProcess: (state) => {
      state.selectedPlan = null;
      state.showPlanModal = false;
      state.showPaymentModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPaymentState, (state) => {
      state.selectedPlan = null;
    });
  },
});

export const { selectPlan, closePlanModal, openPaymentModal, closePaymentModal, resetProcess } = planSlice.actions;
export default planSlice.reducer;
