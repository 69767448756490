import { useEffect } from 'react'

export default function PaymentService() {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'module'
    script.src = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.js'
    document.body.appendChild(script)

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.css'
    document.head.appendChild(link)

    script.onload = () => {
      const ppb = new (window as any).PPaymentButtonBox({
        token:
          'ss2RO9vz77GP55HAEDwkUsHQl9fU1uNz3C1GdJKBqJpTNmqZeJA3yWhiD4TlNGxj-oMf79F7kqMyc34eqkOxQMoC3ngCCNqplA0jaKzqZwv-QqLegku8-5NNEYhCx9G-BeRa4aw1WdFVxlRRPXC3qZuejj-DX6mv-MaAqtMSs-HjJ0c7moOrC6dwAF7CtlY5IF6auGtN1WfhFAXWwgn7vGM2fSqdUU86QV8_6rm7lRGxGFqwu7L0nH9ZNAnfCJ2iOpBDoDsGTIXspE-s8Kep2oi3tu-gQKuth50f1ZHvq9ySnwOKFp0E3zmm-mo-EuE3_z1omwaWua3dCRQTjOqNTotxMjQ',
        amount: 100,
        amountWithoutTax: 100,
        amountWithTax: 0,
        tax: 0,
        service: 0,
        tip: 0,
        reference: 'Validación Tarjeta Activa',
        clientTransactionId: 'ID_UNICO_' + Date.now(),
      })
      ppb.render('pp-button')
    }

    return () => {
      document.body.removeChild(script)
      document.head.removeChild(link)
    }
  }, [])

  return (
    <div>
      <div id="pp-button"></div>
    </div>
  )
}
