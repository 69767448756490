import { Link } from 'react-router-dom';
import logo from '../../../img/logo/logoAlt.png';

export default function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        width: '7rem',
                        height: 'auto',
                        margin: '20px',
                        opacity: 0.9,
                        backgroundColor: 'salmon',
                        padding: '10px',
                        borderRadius: '15px',
                      }}
                    />
                  </Link>
                </div>
                {/* <div className="footer-text">
                  <p>Petclub, tu aliado para el cuidado y salud de tu mascota.</p>
                </div> */}
                <div className="footer-contact">
                  <div className="icon">
                    <i className="fas fa-headphones" />
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="tel:0988746689">0988746689</a>
                    </h4>
                    <span>¡Llámanos ahora!</span>
                  </div>
                </div>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Nuestras Políticas</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/contacts">Política de Privacidad</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Términos y Condiciones</Link>
                    </li>
                    {/* <li>
                      <Link to="/contacts">Política Editorial</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política de Devoluciones</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política de Propiedad Intelectual</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política de Quejas</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Nuestras Condiciones</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Nuestros Servicios</h4>
                <div className="fw-link">
                  <ul>
                    {/* <li>
                      <Link to="/breeder">Nuestros Criadores</Link>
                    </li>
                    <li>
                      <Link to="/adoption">Adopciones</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política Editorial</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política de Devoluciones</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política de Quejas</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Política de Redress</Link>
                    </li> */}
                    <li>
                      <Link to="/contacts">Contáctanos</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Instagram</h4>
                <div className="fw-insta">
                  <ul>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img01.png" alt="Instagram 1" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img02.png" alt="Instagram 2" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img03.png" alt="Instagram 3" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img04.png" alt="Instagram 4" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img05.png" alt="Instagram 5" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img06.png" alt="Instagram 6" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img src="img/images/footer_shape01.png" alt="Forma de pie de página 1" />
        </div>
        <div className="footer-shape shape-two">
          <img src="img/images/footer_shape02.png" alt="Forma de pie de página 2" />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>Copyright © 2024 PetClub. Todos los derechos reservados.</p>
              </div>
            </div>
            {/* <div className="col-md-4 d-none d-md-block">
              <div className="footer-lang">
                <div className="dropdown">
                  <button className="dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="img/icon/united-states.png" alt="English" /> Inglés
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                    <Link className="dropdown-item" to="/">
                      <img src="img/icon/russia.png" alt="Ruso" />
                      Ruso
                    </Link>
                    <Link className="dropdown-item" to="/">
                      <img src="img/icon/thailand.png" alt="Tailandés" />
                      Tailandés
                    </Link>
                    <Link className="dropdown-item" to="/">
                      <img src="img/icon/india.png" alt="Hindi" />
                      Hindi
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
